import React from 'react'
import Participation1 from '../../../public/home/participation1.svg';
import Participation2 from '../../../public/home/participation2.svg';
import QuizhubLogo from '../../../public/home/Quizhub.svg';
import CerthubLogo from '../../../public/home/Certhub.svg';
import Image from 'next/future/image'

const HomeParticipation = () => {
  return (
    <>
      <div className="usage-container py-md-3 px-md-5 py-5 px-2">
        <div className="row g-0">
          <div className="col pt-md-5">
            <div className="usage-title text-center">
              <span className='color'> Engage </span>and  <span className='color'>Reward</span> Participants
            </div>
            <h2 className='sub-text w-75 mx-auto text-center'>
              You can use QuizHub to engage participants with live quizzing and CertHub for automatically issuing participation certificates on feedback submission
            </h2>
          </div>
        </div>
        <div className="row g-0 mt-4">
          <div className="col-10 offset-1 text-center">
            <div className="g-0 pb-md-5 d-flex umbrella-sites justify-content-center align-items-center flex-grow mt-4 pt-2">
              <div className='site-card p-4 d-flex flex-column'>
                <Image src={QuizhubLogo} className='logos ms-1 mb-3' alt="KonfHub Online Quiz" width={141} height={70}/>
                <span className='section-text'>QuizHub brings you live quizzes to gamify & simplify tech learning. Participate in a quiz, a tournament or even make your own!</span>
                <a target="_blank" className='width-small' href="https://quiz.konfhub.com" rel="noopener noreferrer">
                  <span className='learn-more d-flex align-items-center mt-4'>Learn more  <i class="fas ms-2 fa-arrow-right"></i></span>
                </a>
                <Image src={Participation1} className="participation-1" alt="quizhub image" width={500} height={340}/>
              </div>
              <div className='site-card p-4 d-flex flex-column'>
                <Image src={CerthubLogo} className='logos ms-1' alt="certhub logo" width={141} height={70}/>
                <span className='section-text'>Collect feedback from participants and/or automatically issue customized participation certificates on feedback submission.</span>
                <a target="_blank" className='width-small' href="https://cert.konfhub.com" rel="noopener noreferrer">
                  <span className='learn-more d-flex align-items-center mt-4'>Learn more  <i class="fas ms-2 fa-arrow-right"></i></span>
                </a>
                <Image src={Participation2} className="participation-2" alt="Generate certification for quiz instantly" width={500} height={340}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
      .usage-container {
          background: #f6f9fc;
      }

      .usage-container .usage-title {
          color: #572148;
          font-weight: 800;
          font-size: 50px;
          font-family: "Nunito";
      }
      .color{
          color: #fb5850;
      }
      .section-text{
        font-family: Nunito;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
        color: #FFFFFF;
      }
      .width-small{
        width: fit-content;
block-size: fit-content;

      }
      .learn-more{
        font-family: Nunito;
        font-size: 17px;
        font-weight: 800;
        line-height: 26px;
        letter-spacing: 0px;
        cursor: pointer;
        text-align: left;
        color: #ffffff;
      }
      .learn-more:hover{
        color: #fb5850;
      }
      .participation-1{
        margin-right: -185px;
      }
      .participation-2{
        margin-right: -270px;
      }
      .sub-text{
          font-family: 'Nunito';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          text-align: center;
          color: #4F4F4F;
      }
      .logos{
        margin-left: -12px;
        height: 120px;
        width: 170px;
      }
      .umbrella-sites{
        flex-direction: row;
        gap: 50px;
      }
      .site-card{
        width: 612px;
        height: 620px;
        overflow: hidden;
        text-align: left !important;
        background: #572148;
        box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
      }

      /* XS screens */
      @media only screen and (max-width: 768px) {
        .usage-container .usage-title {
            font-size: 35px;
        }
      }

      /* XS screens */
      @media only screen and (max-width: 480px) {
        .site-card{
          width: 320px;
        }
        .umbrella-sites{
          flex-direction: column;
        }
      }

      /* XS screens */
      @media (min-width: 480px) and (max-width: 992px) {
          .site-card{
            width: 450px;
          }
          .umbrella-sites{
            flex-direction: column;
          }
      }
  `}</style>
    </>
  )
}

export default HomeParticipation
